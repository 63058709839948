var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MultiChartWidgetStyled', [_vm.widgetHeader ? _c('WidgetHeaderMolecule', {
    attrs: {
      "title": _vm.$t('widgetTypes.MULTI_CHART'),
      "assetDimensions": _vm.assetDimensionDetails
    }
  }) : _vm._e(), _c('GraphStyled', {
    attrs: {
      "hasNoData": _vm.hasNoData
    }
  }, [_vm.hasNoData ? _c('NoDataStyled', [_vm._v(_vm._s(_vm.message))]) : _c('MultiChart', {
    attrs: {
      "option": _vm.option
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }