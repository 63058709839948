<template>
  <TalpaLoaderWrapper v-if="$apollo.loading || assetIds.length < 1" />
  <MultiChartWidget v-else :widgetHeader="true" :assetDimensionDetails="assetDimensionDetails" :assets="assetIds.length" :option="option" />
</template>

<script>
import get from 'lodash/get'
import chroma from 'chroma-js'
import resizeMixin from '@/mixins/resize'
import MultiChartWidget from '../../Molecules/MultiChartWidget'
import { TalpaLoaderWrapper } from '@common/components'
import { TimeframeMixin } from '@common/mixins'
import { DarkEChartTheme, LightEChartTheme } from '@styles/themes'
import { DateTime } from 'luxon'
import localesMixin from '@/mixins/locales'
import { getAssetDimensionNameByLocale } from '@common/utils/src'
import { useAssetStore } from '@/stores/assets'
import ASSET_DIMENSION_DATA_QUERY from '#/graphql/assetDimensions/assetDimensionDataMultiChartWidget.gql'

export default {
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  mixins: [resizeMixin, TimeframeMixin, localesMixin],
  inject: ['theme', 'uiSettings'],
  props: {
    widget: {
      type: Object,
      required: true,
    },
  },
  components: {
    MultiChartWidget,
    TalpaLoaderWrapper,
  },
  data() {
    return {
      assetDimensionData: null,
      aggrByTimebuckets: {},
      totals: {},
    }
  },
  computed: {
    assetIds() {
      const selectedAssets = this.assetStore.assetsSelected()
      return selectedAssets.map(({ id }) => id)
    },
    locale() {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      return replacedDates.slice(0, 2)
    },
    dimensions() {
      const dimensions = this.widget?.dimensions ?? []
      return dimensions.sort((a, b) => {
        return a.position - b.position
      })
    },
    colors() {
      return this.theme.isDark ? DarkEChartTheme.color : LightEChartTheme.color
    },
    assetDimensionDetails() {
      return this.dimensions.map((dimension, i) => {
        const assetDimension = get(dimension, 'assetDimension', '')
        const assetDimensionName = assetDimension.name
        const data = get(this.totals, assetDimensionName, null)
        const unitUI = this.unitUI(assetDimension)
        const unitSI = this.unitSI(assetDimension)
        const unit = unitUI ? unitUI.replace('mt', 't') : unitSI
        const value = data !== null ? this.convUnit(data, unitSI, unitUI, 2, false, false) : '-'

        return {
          id: assetDimensionName,
          title: assetDimension?.nameTranslations
            ? getAssetDimensionNameByLocale(assetDimension?.nameTranslations, this.locale)
            : assetDimensionName,
          value,
          unit,
          color: this.colors[i],
        }
      })
    },
    series() {
      const seriesMapped = this.dimensions.map((dimension, i) => {
        if (i > 2) {
          throw new Error(this.$t('multiChart.warning'))
        }
        const name = get(dimension, 'assetDimension.name', '')
        const assetDimension = get(dimension, 'assetDimension', '')
        const unitUI = this.unitUI(assetDimension)
        const unitSI = this.unitSI(assetDimension)
        const unit = unitUI ? unitUI.replace('mt', 't') : unitSI

        const data = this.timeBuckets.map(timeBucket => {
          const found = this.aggrByTimebuckets?.[name]?.find(f => {
            const time = DateTime.fromISO(f.time)
            return timeBucket.contains(time)
          })
          return {
            value: found !== undefined ? this.convUnit(found.floatValue, unitSI, unitUI, 2, false) : null,
            unit: unit ? unit : '',
          }
        })

        const position = dimension.position
        const series = {
          name: assetDimension?.nameTranslations ? getAssetDimensionNameByLocale(assetDimension?.nameTranslations, this.locale) : name,
          data,
          color: this.colors[i],
          type: get(dimension, 'graphType', 'LINE') === 'COLUMN' ? 'bar' : 'line',
          yAxisId: unit ? unit : 'count',
          symbol: 'circle',
          symbolSize: 5,
          position,
          connectNulls: false,
        }
        return series
      })
      return seriesMapped.filter(d => d.data.length !== 0)
    },
    relatedDataGroupedByUnit() {
      return this.series.reduce((groups, relatedUnit) => {
        const key = relatedUnit?.yAxisId
        if (!key) {
          return groups
        }
        if (groups.has(key)) {
          groups.get(key).push(relatedUnit)
        } else {
          groups.set(key, [relatedUnit])
        }
        return groups
      }, new Map())
    },
    yAxis() {
      return Array.from(this.relatedDataGroupedByUnit.keys())
        .reverse()
        .map((key, i) => {
          return {
            id: key,
            name: key,
            nameRotate: 45,
            type: 'value',
            unit: key,
            position: 'left',
            min: 0,
            offset: i * 40,
            splitNumber: 2,
            splitLine: {
              show: true,
              lineStyle: {
                color: this.theme.colors.atomic.mildgrey,
              },
            },
            axisLabel: {
              width: 40,
              overflow: 'break',
              rotate: 45,
              formatter: params => {
                return this.numberLocalized(params)
              },
            },
            axisTick: {
              show: true,
              alignWithLabel: true,
            },
            axisLine: {
              show: true,
              onZero: 0,
              lineStyle: {
                color: this.theme.isDark ? this.theme.colors.lightestGrey : this.theme.colors.darkGrey,
              },
            },
          }
        })
    },
    xAxis() {
      return {
        type: 'category',
        data: this.timeBucketsUI,
        splitLine: {
          show: true,
          lineStyle: {
            color: this.theme.colors.atomic.mildgrey,
          },
        },
        axisLabel: {
          color: this.theme.colors.textActivePrimary,
          align: 'center',
        },
        axisTick: {
          show: true,
          alignWithLabel: false,
        },
      }
    },
    option() {
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            shadowStyle: {
              color: chroma(this.theme.colors.mediumGrey).alpha(0.2).css(),
            },
          },
          formatter: function (params) {
            let tooltip = `${params[0].name}`
            const paramFiltered = params.filter(item => item.value !== undefined)
            paramFiltered.forEach(param => {
              tooltip += `<br/> ${param.marker} ${param.seriesName}
                        <B style="float:right;margin-left:1rem">
                          ${param.data?.value ?? '-'}<span style="margin-left:0.5rem">${param.data?.unit}<span>
                        </B>`
            })
            return tooltip
          },
        },
        grid: {
          left: this.yAxis.length * 15,
          right: '15',
          bottom: '15',
          top: '55',
          containLabel: true,
        },
        series: this.series,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
      }
      return option
    },
  },
  methods: {
    unitSI(assetDimension) {
      return get(assetDimension, 'physicalUnitSI', null)
    },
    unitUI(assetDimension) {
      const unitUIMetric = get(assetDimension, 'physicalUnitUIMetric', '')
      const unitUIImperial = get(assetDimension, 'physicalUnitUIImperial', null)
      const unitUI = unitUIImperial && this.selectedUIUnitSystem === 'IMPERIAL' ? unitUIImperial : unitUIMetric
      return unitUI
    },
  },
  watch: {
    dimensions: {
      handler(dimensions) {
        dimensions.forEach(dimension => {
          const name = dimension?.assetDimension?.name ?? null
          if (name) {
            this.$apollo.addSmartQuery(name, {
              query: ASSET_DIMENSION_DATA_QUERY,
              variables() {
                return {
                  where: {
                    assetDimension: {
                      name,
                    },
                    timeframe: this.selectedTimeframeParam,
                    assets: {
                      id_in: this.assetIds,
                    },
                  },
                }
              },
              skip() {
                return this.dimensions.length < 1 || this.assetIds.length < 1 || !this.selectedTimeframe
              },
              result(res) {
                const total = res?.data?.assetDimensionData?.total ?? null
                const aggrByTimebuckets = res?.data?.assetDimensionData?.aggrByTimebuckets ?? []
                this.$set(this.totals, name, total)
                this.$set(this.aggrByTimebuckets, name, aggrByTimebuckets)
              },
              manual: true,
            })
          }
        })
      },
      immediate: true,
    },
  },
}
</script>
