<template>
  <MultiChartWidgetStyled>
    <WidgetHeaderMolecule :title="$t('widgetTypes.MULTI_CHART')" :assetDimensions="assetDimensionDetails" v-if="widgetHeader" />
    <GraphStyled :hasNoData="hasNoData">
      <NoDataStyled v-if="hasNoData">{{ message }}</NoDataStyled>
      <MultiChart :option="option" v-else />
    </GraphStyled>
  </MultiChartWidgetStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import MultiChart from '../Atoms/MultiChart'
import WidgetHeaderMolecule from '@/components/Atomic/Molecules/WidgetHeaderMolecule'

const MultiChartWidgetStyled = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    'widgetHeader'
    'graph';
  grid-template-rows: min-content 1fr;
  grid-template-columns: 1fr;
`
const GraphStyled = styled('div')`
  grid-area: graph;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.25s ease;
  padding: 0.5rem;
  ${({ hasNoData }) => hasNoData && `display:flex; align-items: center;`};
`
const NoDataStyled = styled('div')`
  display: flex;
  justify-content: center;
  background: ${props => props.theme.colors.solidBG};
  border-radius: 0.5rem;
`

export default {
  inject: ['theme'],
  props: {
    option: {
      required: true,
      type: Object,
    },
    assetDimensionDetails: {
      required: false,
      type: Array,
    },
    assets: {
      required: false,
      type: Number,
    },
    widgetHeader: {
      required: false,
      type: Boolean,
      default: false,
    },
    showAssetCount: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  components: {
    MultiChartWidgetStyled,
    GraphStyled,
    MultiChart,
    NoDataStyled,
    WidgetHeaderMolecule,
  },
  computed: {
    hasNoData() {
      const hasData = this.option?.series?.some(serie => serie?.data?.some(dp => dp.value !== undefined && dp.value !== null))
      return !hasData
    },
    message() {
      const dimensionNames = this.option?.series?.map(({ name }) => name)?.toString()
      return this.$t('messages.noDimensionData', { dimension: dimensionNames?.split(',').join(', ') })
    },
  },
}
</script>
