<template>
  <AssetDimensionMultiChartWidget v-if="widget" :widget="widget" />
</template>

<script>
import AssetDimensionMultiChartWidget from '@/components/Atomic/Organisms/Dashboard/AssetDimensionMultiChartWidget'

export default {
  props: {
    widget: {
      type: Object,
      required: false,
    },
  },
  components: {
    AssetDimensionMultiChartWidget,
  },
}
</script>
