var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$apollo.loading || _vm.assetIds.length < 1 ? _c('TalpaLoaderWrapper') : _c('MultiChartWidget', {
    attrs: {
      "widgetHeader": true,
      "assetDimensionDetails": _vm.assetDimensionDetails,
      "assets": _vm.assetIds.length,
      "option": _vm.option
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }