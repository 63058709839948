<template>
  <MultiChartCanvasWrapper>
    <VChart :option="option" :updateOptions="{ notMerge: true }" autoresize />
  </MultiChartCanvasWrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { LineChart, BarChart } from 'echarts/charts'
import VChart from 'vue-echarts'
import 'echarts/lib/component/grid'
import { TooltipComponent, LegendComponent } from 'echarts/components'

use([CanvasRenderer, LineChart, BarChart, TooltipComponent, LegendComponent])

const MultiChartCanvasWrapper = styled('div')`
  display: flex;
  position: relative;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export default {
  props: {
    option: {
      required: true,
      type: Object,
    },
  },
  components: {
    MultiChartCanvasWrapper,
    VChart,
  },
}
</script>
